<template>
  <div>
    <automated-email-template
      v-if="tab === 'automatedEmailTemplate'"
    ></automated-email-template>
    <manual-email-template
      v-else-if="tab === 'manualEmailTemplate'"
    ></manual-email-template>
    <bulk-emails v-else></bulk-emails>
  </div>
</template>
<script>
export default {
  name: "EmailSettings",
  components: {
    AutomatedEmailTemplate: () =>
      import("./AutomateEmailTemplate/AutomatedEmailTemplate"),
    ManualEmailTemplate: () =>
      import("./ManualEmailTemplate/ManualEmailTemplate"),
    BulkEmails: () => import("./BulkEmails/BulkEmails"),
  },
  data() {
    return {
      tab: "automatedEmailTemplate",
    };
  },
  computed: {},
  watch: {},
  methods: {
    changeTab(tab) {
      this.tab = tab;
    },
  },
  mounted() {},
  created() {
    this.$root.$refs.Email = this;
  },
  beforeDestroy() {
    this.$root.$refs.adminLayout.resetEmailTab();
  },
};
</script>
