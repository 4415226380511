<template>
  <div class="py-15 px-7">
    <div class="text-center" v-if="loading" style="min-height: 500px">
      <v-progress-circular
        :size="100"
        :width="10"
        style="
          text-align: center;
          padding-top: 20px;
          padding-bottom: 20px;
          margin-top: 200px;
        "
        color="#7253CF"
        indeterminate
        ><v-overlay></v-overlay
      ></v-progress-circular>
    </div>
    <v-card v-else>
      <v-card-text class="py-0">
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col
                cols="7"
                style="border-right: 1px solid #ffba00"
                class="py-13 px-7"
              >
                <v-row>
                  <v-col cols="12">
                    <div class="heading" style="font-family: Lato">
                      {{ emailTitle }}
                    </div>
                    <div class="subHeading">
                      {{ emailDescription }}
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <div class="fieldHeading px-1">Email Subject</div>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      placeholder="Email Subject"
                      outlined
                      dense
                      class="formFields"
                      v-model="emailSubject"
                      color="#7253CF"
                      :disabled="formLoading"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <div class="fieldHeading px-1">Email Body</div>
                  </v-col>
                  <v-col cols="12">
                    <div style="width: 100%; height: 370px">
                      <quillEditor
                        v-model="emailBody"
                        :options="editorOption"
                      />
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="d-flex justify-end">
                    <v-btn
                      dark
                      height="45"
                      width="180"
                      color="#2c1963"
                      class="text-capitalize"
                      style="border-radius: 10px"
                      :loading="formLoading"
                      @click="submitAutomatedForm"
                    >
                      <span>Save</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="5" class="py-10" style="background-color: #fffcf5">
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <div class="keywordHeading">Available Keywords</div>
                      <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12">
                      <div class="keywordDescription">
                        There are the available keywords to be used in this
                        email templates
                      </div>
                    </v-col>
                  </v-row>
                  <v-row v-for="(item, i) in keywordsList" :key="i">
                    <v-col cols="5">
                      <div class="keywordTitle px-4 py-2">{{ item.title }}</div>
                    </v-col>
                    <v-col cols="1">
                      <div style="font-size: 19px; color: black" class="py-2">
                        :
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <div class="keyword py-2 px-4">{{ item.key }}</div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import { ROUTER_URL } from "@/constants/urls";
import { mapActions } from "vuex";
import Axios from "@/api/BaseAxios";
import {
  API_EMAIL_GET_DETAIL,
  API_EMAIL_GET_KEYWORDS,
  API_EMAIL_AUTOMATED_MANUAL_PATCH,
} from "@/constants/APIUrls";
export default {
  name: "AutomatedEmailForm",
  components: {
    quillEditor,
  },
  data() {
    return {
      loading: false,
      formLoading: false,
      email_id: null,
      emailTitle: "",
      emailDescription: "",
      emailSubject: "",
      emailBody: "",
      keywordsList: [],
      editorOption: {
        placeholder: "Email body",
        readOnly: true,
        theme: "snow",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            //   [{ script: "sub" }, { script: "super" }],
            //   [{ indent: "-1" }, { indent: "+1" }],
            //   [{ size: ["small", false, "large", "huge"] }],
            //   [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            //   [{ font: [] }],
            [{ align: [] }],
            [{ direction: "rtl" }], // text direction
            ["clean"],
          ],
        },
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    ...mapActions({
      showToast: "snackBar/showToast",
    }),
    getAutomatedEmailTemplate() {
      const self = this;
      const successHandler = (res) => {
        this.emailTitle = res.data.email_template_detail.email_title;
        this.emailDescription =
          res.data.email_template_detail.email_description;
        this.emailSubject = res.data.email_template_detail.email_subject;
        this.emailBody = res.data.email_template_detail.email_body;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      const finallyHandler = function () {
        self.getKeyWords();
      };
      let formData = {};
      formData["email_template_id"] = this.email_id;
      Axios.request_GET(
        API_EMAIL_GET_DETAIL,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    getKeyWords() {
      const successHandler = (res) => {
        console.log(res.data);
        this.keywordsList = res.data.email_template_keyword_list;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      Axios.request_GET(
        API_EMAIL_GET_KEYWORDS,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    submitAutomatedForm() {
      this.formLoading = true;
      const successHandler = (res) => {
        console.log(res.data);
        this.formLoading = false;
        this.$router.push({
          name: ROUTER_URL.adminPanel.children.emailSettings.name,
        });
        setTimeout(() => {
          this.$root.$refs.adminLayout.routeBackEmailTab(
            "automatedEmailTemplate"
          );
        }, 500);
        this.showToast({
          message: "Automated email template updated.",
          color: "s",
        });
      };
      const failureHandler = (res) => {
        console.log(res.data);
        this.formLoading = false;
        this.showToast({
          message: "Something Went Wrong",
          color: "e",
        });
      };
      let formData = {};
      formData["email_subject"] = this.emailSubject;
      formData["email_body"] = this.emailBody;
      formData["email_template_id"] = this.email_id;
      Axios.request_PATCH(
        API_EMAIL_AUTOMATED_MANUAL_PATCH,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
  },
  mounted() {
    this.email_id = this.$route.query.id;
    if (this.email_id) {
      this.loading = true;
      this.getAutomatedEmailTemplate();
    }
  },
};
</script>
<style scoped>
* {
  font-family: Lato;
}
.heading {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */

  color: #000000;
}
.keywordHeading {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */
  color: #2c1963;
  border-bottom: 4px solid #2c1963;
  width: 50%;
}
.keywordDescription {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;
  color: #000000;
}
.subHeading {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 25px;

  color: #757575;
}
.fieldHeading {
  color: rgba(0, 0, 0, 0.8);
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  /* identical to box height */

  color: #757575;
}
.quill-editor {
  height: 300px;
  font-family: Lato;
}
.keywordTitle {
  color: #000000;
  border: 2px dashed #2c1963;
  font-size: 17px;
  border-radius: 5px;
}
.keyword {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  /* or 310% */

  color: #2c1963;
}
.v-btn >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  /* or 29px */
  color: #ffffff;
  letter-spacing: 0px;
  font-size: 17px;
}
</style>
